import { createStore } from "solid-js/store";
import { createEducationInfoInputs } from "../form";
import { FormPageProps } from "../model";
import { createSignal, splitProps } from "solid-js";
import { Cmp, Mod } from ":mods";
import { updateShowExhibition } from "../apis/show-exhibition";

export function EducationInfo(props: FormPageProps) {
  const [local, others] = splitProps(props, ["stage_id", "data"]);
  console.log("local.data", local.data);
  const FormInputs = createEducationInfoInputs();
  const [study, setStudy] = createSignal([]);

  const [EducationData, setEducationData] = createStore([
    {
      key: "box-02",
    },
    {
      qualification: 1,
    },
    {
      study_field: 1,
    },
  ]);

  if (local.data) {
    setStudy(local?.data[1]?.study_field.study_field?.map((r) => r?.name));
  }

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    const values = FormInputs.Actions.getValuesSnakecase();
    if (!!values.study_field) {
      values.study_field = local?.data[1]?.study_field.study_field?.find((r) => r.name === values.study_field)?.id;
    }
    console.log("values.qualification", values.qualification);

    // if (!!values.qualification){
    //   console.log("local?.data[0]?.education_info.qualification", local?.data[0]?.education_info.qualification)
    //   values.qualification = local?.data[0]?.education_info.qualification?.find((r)=>r.name === values.qualification)?.id
    // }

    console.log(" values.study_field after updated", values.study_field);
    setEducationData(1, "qualification", parseInt(values.qualification));
    setEducationData(2, "study_field", parseInt(values.study_field));

    const formData = new FormData();

    formData.append("data", JSON.stringify(EducationData));
    console.log("JSON.stringify(EducationData)", JSON.stringify(EducationData));

    await updateShowExhibition(local.stage_id, formData).then((message) => {
      console.log(message, " :message");
      Cmp.Alert.actions.pushToast({
        type: "success",
        message: "Education Info Updated successfully",
      });
    });

    // props.onFormSubmit(values);
  };

  return (
    <main class="flex flex-col items-center w-full justify-between">
      <section class="flex flex-col gap-40px w-full pb-80px pt-40px">
        <form class="flex flex-col gap-20px">
          {local.data?.map((info) => (
            <div>
              {info?.education_info && (
                <div class="flex flex-col gap-10px">
                  <FormInputs.Qualification.Label title="What is your highest academic qualification">
                    <FormInputs.Qualification.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                  </FormInputs.Qualification.Label>

                  {info.education_info.qualification?.map((degree, index) => (
                    <div class="flex items-center gap-10px border-2 border#p border-solid text-0.8rem px-2.5 py-2">
                      <FormInputs.Qualification.Input
                        type="radio"
                        class=""
                        placeholder=""
                        name="academicfield"
                        id={degree.id.toString()}
                      />
                      <p class="text-16px">{degree.name}</p>
                    </div>
                  ))}
                </div>
              )}

              {info?.study_field && (
                <div class="flex flex-col gap-10px">
                  <FormInputs.StudyField.Label title="What field did you study?" class="">
                    <FormInputs.StudyField.Error class="form-input-error flex$ fight$ mx-2 text-0.7rem" />
                  </FormInputs.StudyField.Label>
                  <FormInputs.StudyField.Select
                    class="form-select border-2 border#p !bg#paper border-solid text-0.8rem !py-2 mb-2"
                    placeholder=""
                    options={study()}
                    optionGroupClass={"!text#p !bg#paper !h-200px !m-0"}
                  />
                </div>
              )}
            </div>
          ))}

          <div class="flex justify-end">
            <FormInputs.Actions.Button
              class="disabled:bg#disabled  flex items-center justify-center text-16px cursor-pointer bg#p w-175px h-44px text#n-50  h-23px mt-20px mb-80px px-30px py-20px rounded-sm"
              onclick={onSubmit}
              statusValid={"Save and Close"}
              statusInvalid={"Save and Close"}
            />
          </div>
        </form>
      </section>
    </main>
  );
}
